import { createGlobalStyle } from 'styled-components'
import {
  mediaMax,
  calculateResponsiveSize,
  calculateMobileResponsiveSize
} from 'styles/media-queries'

const GlobalStyle = createGlobalStyle`
  :root {
  }
  html, body{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 14px;
    color: #52403A;
    line-height: 1.25;
    background: #F9F1EC;
    min-width: 320px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body{
    overflow-y: scroll;
  }
  body.hidden {
    height: 100dvh !important;
  }
  body.hidden .home-wrapper {
    height: 100dvh;
    overflow: hidden;
  }
  html, body, A, input, select, textarea, button{
    font-family: 'Neuzeit Grotesk', Arial, Tahoma, Geneva, Kalimati, sans-serif;
  }
  A{
    text-decoration: none;
    color: #52403A;
    transition: all 0.25s ease 0s;
    outline: none;
  }
  A:hover{
    text-decoration: underline;
  }
  input, select, textarea {
    vertical-align: top;
    outline: none;
  }
  /* ----- Safari profile icon ----- */
  input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
  textarea {
    resize: vertical;
  }
  *{ -webkit-tap-highlight-color: transparent; box-sizing: border-box; }
  img, object, iframe, svg{ border: 0; vertical-align: top; }
  svg path, svg circle, svg line{ transition: all 0.25s ease 0s; }
  button { font-weight: 400; }
  .home-content {
    will-change: transform; 
  }
  /* ----- react-select ----- */
  @keyframes selectSlideInOut {
    0% {
      transform: translateY(-1px);
    }
    100% {
      transform: translateY(0);
    }
  }

  /* ----- UserWay ----- */
  body .uwy {
    opacity: 0;
    visibility: hidden;
    animation: 2s ease 1s normal forwards 1 fadeIn;
    @keyframes fadeIn {
      0% {
        opacity: 0;
        visibility: hidden;
      }
      100% {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  body .uwy .uai, body .uwy .uai > span {
    width: ${calculateResponsiveSize(40)} !important;
    height: ${calculateResponsiveSize(40)} !important;
  }
  body .uwy .uai > span > img {
    width: 100% !important;
    height: auto !important;
  }
  ${mediaMax('mobile')} {
    body .uwy .uai, body .uwy .uai > span {
      width: ${calculateMobileResponsiveSize(30)} !important;
      height: ${calculateMobileResponsiveSize(30)} !important;
    }
  }
`

export default GlobalStyle
